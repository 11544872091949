import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import axios from "axios";
import {
  GET_ORGANIZATION_DETAILS,
  GET_ORG_BASED_ROSTER_DATA,
  TOGGLE_ROSTER,
  ADD_NEW_ROSTER,
  ADD_NEW_ORGANIZATION,
  GET_TITLE_ROLE,
  GET_TITLE_ROLE_OPTION,
  MAP_NEW_TITLE_ROLE,
  EXPORT_ROSTER,
  GET_MANAGE_ROLES,
  EXPORT_ACTIVE_RECORD,
} from "../actions";
import {
  getOrganizationDetailsSuccess,
  getOrganizationDetailsError,
  getOrgbasedRosterdataSuccess,
  getOrgbasedRosterdataError,
  toggleRosterSuccess,
  toggleRosterError,
  addNewRosterSuccess,
  addNewRosterError,
  addNewOrganizationSuccess,
  addNewOrganizationError,
  getTitleRoleSuccess,
  getTitleRoleError,
  getTitleRoleOptionSuccess,
  getTitleRoleOptionError,
  mapNewTitleRoleSuccess,
  mapNewTitleRoleError,
  exportRosterSuccess,
  exportRosterError,
  getManageRolesSuccess,
  getManageRolesError,
  exportActiveRosterSuccess,
  exportActiveRosterError,
} from "./action";
import { api, DataEntryAPI, AIMWithoutCred } from "../../config/api";
import {
  getApiHeader,
  getCompanyGUID,
  getLoginPersonId,
} from "../../helpers/utils";
import { toast } from "react-toastify";

// Saga worker functions
function* apiCallSaga(apiCall, successAction, errorAction, action) {
  try {
    const { payload } = action || {};
    const response = yield call(apiCall, payload);
    yield put(successAction(response));
  } catch (error) {
    yield put(errorAction(error));
  }
}
// Helper function for API calls
const apiCall = (method, url, data) => {
  return axios({
    method,
    headers: getApiHeader(),
    url,
    data,
  })
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err);
      throw err;
    });
};

const getOrganizationDetailsCallAsync = () =>
  apiCall(
    "GET",
    `${DataEntryAPI}/Get_OrganizationDetails?companyguid=${getCompanyGUID()}`
  );

const organizationBasedDataCallAsync = (payload) =>
  apiCall(
    "GET",
    `${DataEntryAPI}/GetOrgbasedRosterdata?companyguid=${getCompanyGUID()}&orgid=${
      payload.orgId
    }`
  );

const toggleRosterCallAsync = (payload) => {
  payload.companyGuid = getCompanyGUID();
  return apiCall("POST", `${DataEntryAPI}/ToggleRoster`, payload);
};

const addNewRosterCallAsync = (payload) => {
  payload.companyGuid = getCompanyGUID();
  return apiCall("POST", `${DataEntryAPI}/AddNewRoster`, payload);
};

const addNewOrganizationCallAsync = (payload) => {
  payload.companyGuid = getCompanyGUID();
  return apiCall("POST", `${DataEntryAPI}/AddNewOrganization`, payload);
};

const getTitleRoleCallAsync = (payload) =>
  apiCall(
    "GET",
    `${DataEntryAPI}/GetTitleRole?companyguid=${getCompanyGUID()}&orgid=${
      payload.orgId
    }`
  );

const getTitleRoleOptionCallAsync = (payload) =>
  apiCall(
    "GET",
    `${DataEntryAPI}/GetTitleRoleOptions?companyguid=${getCompanyGUID()}&orgid=${
      payload.orgId
    }`
  );

const mapNewTitleRoleCallAsync = (payload) => {
  payload.companyGuid = getCompanyGUID();
  return apiCall("POST", `${DataEntryAPI}/MapNewTitleRole`, payload);
};

const exportRosterCallAsync = (payload) =>
  apiCall(
    "GET",
    `${DataEntryAPI}/ExportRoster?companyguid=${getCompanyGUID()}&displayname=${
      payload.displayname
    }`
  );
const getManageRolesCallAsync = (payload) =>
  apiCall(
    "GET",
    `${DataEntryAPI}/Get_ManageRoles?companyguid=${getCompanyGUID()}&orgid=${
      payload.orgid
    }&roleTypeName=${payload.roleTypeName}`
  );
const exportActiveRosterCallAsync = (payload) =>
  apiCall(
    "GET",
    `${AIMWithoutCred}/ExportData/Getactiverecord?recordDate=${payload.recordDate}`
  );
export function* getOrganizationDetails() {
  yield takeLatest(
    GET_ORGANIZATION_DETAILS,
    apiCallSaga,
    getOrganizationDetailsCallAsync,
    getOrganizationDetailsSuccess,
    getOrganizationDetailsError
  );
}

export function* OrganizationBasedData() {
  yield takeLatest(
    GET_ORG_BASED_ROSTER_DATA,
    apiCallSaga,
    organizationBasedDataCallAsync,
    getOrgbasedRosterdataSuccess,
    getOrgbasedRosterdataError
  );
}

export function* toggleRoster() {
  yield takeEvery(
    TOGGLE_ROSTER,
    apiCallSaga,
    toggleRosterCallAsync,
    toggleRosterSuccess,
    toggleRosterError
  );
}

export function* addNewRoster() {
  yield takeEvery(
    ADD_NEW_ROSTER,
    apiCallSaga,
    addNewRosterCallAsync,
    addNewRosterSuccess,
    addNewRosterError
  );
}

export function* addNewOrganization() {
  yield takeEvery(
    ADD_NEW_ORGANIZATION,
    apiCallSaga,
    addNewOrganizationCallAsync,
    addNewOrganizationSuccess,
    addNewOrganizationError
  );
}

export function* getTitleRole() {
  yield takeEvery(
    GET_TITLE_ROLE,
    apiCallSaga,
    getTitleRoleCallAsync,
    getTitleRoleSuccess,
    getTitleRoleError
  );
}

export function* getTitleRoleOption() {
  yield takeEvery(
    GET_TITLE_ROLE_OPTION,
    apiCallSaga,
    getTitleRoleOptionCallAsync,
    getTitleRoleOptionSuccess,
    getTitleRoleOptionError
  );
}

export function* mapNewTitleRole() {
  yield takeEvery(
    MAP_NEW_TITLE_ROLE,
    apiCallSaga,
    mapNewTitleRoleCallAsync,
    mapNewTitleRoleSuccess,
    mapNewTitleRoleError
  );
}
export function* exportRoster() {
  yield takeLatest(
    EXPORT_ROSTER,
    apiCallSaga,
    exportRosterCallAsync,
    exportRosterSuccess,
    exportRosterError
  );
}
export function* getManageRoles() {
  yield takeLatest(
    GET_MANAGE_ROLES,
    apiCallSaga,
    getManageRolesCallAsync,
    getManageRolesSuccess,
    getManageRolesError
  );
}
export function* exportActiveRoster() {
  yield takeLatest(
    EXPORT_ACTIVE_RECORD,
    apiCallSaga,
    exportActiveRosterCallAsync,
    exportActiveRosterSuccess,
    exportActiveRosterError
  );
}
export default function* rootSaga() {
  yield all([
    fork(getOrganizationDetails),
    fork(OrganizationBasedData),
    fork(toggleRoster),
    fork(addNewRoster),
    fork(addNewOrganization),
    fork(getTitleRole),
    fork(getTitleRoleOption),
    fork(mapNewTitleRole),
    fork(exportRoster),
    fork(getManageRoles),
    fork(exportActiveRoster),
  ]);
}
