import {
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_DETAILS_SUCCESS,
  GET_ORGANIZATION_DETAILS_ERROR,
  GET_ORG_BASED_ROSTER_DATA,
  GET_ORG_BASED_ROSTER_DATA_SUCCESS,
  GET_ORG_BASED_ROSTER_DATA_ERROR,
  TOGGLE_ROSTER,
  TOGGLE_ROSTER_SUCCESS,
  TOGGLE_ROSTER_ERROR,
  ADD_NEW_ROSTER,
  ADD_NEW_ROSTER_SUCCESS,
  ADD_NEW_ROSTER_ERROR,
  ADD_NEW_ORGANIZATION,
  ADD_NEW_ORGANIZATION_SUCCESS,
  ADD_NEW_ORGANIZATION_ERROR,
  GET_TITLE_ROLE,
  GET_TITLE_ROLE_SUCCESS,
  GET_TITLE_ROLE_ERROR,
  GET_TITLE_ROLE_OPTION,
  GET_TITLE_ROLE_OPTION_SUCCESS,
  GET_TITLE_ROLE_OPTION_ERROR,
  MAP_NEW_TITLE_ROLE,
  MAP_NEW_TITLE_ROLE_SUCCESS,
  MAP_NEW_TITLE_ROLE_ERROR,
  EXPORT_ROSTER,
  EXPORT_ROSTER_SUCCESS,
  EXPORT_ROSTER_RESET,
  EXPORT_ROSTER_ERROR,
  GET_MANAGE_ROLES,
  GET_MANAGE_ROLES_SUCCESS,
  GET_MANAGE_ROLES_ERROR,
  EXPORT_ACTIVE_RECORD,
  EXPORT_ACTIVE_RECORD_SUCCESS,
  EXPORT_ACTIVE_RECORD_RESET,
  EXPORT_ACTIVE_RECORD_ERROR,
} from "../actions";

export const getOrganizationDetails = (data) => ({
  type: GET_ORGANIZATION_DETAILS,
  payload: data,
});
export const getOrganizationDetailsSuccess = (info) => ({
  type: GET_ORGANIZATION_DETAILS_SUCCESS,
  payload: info,
});
export const getOrganizationDetailsError = (message) => ({
  type: GET_ORGANIZATION_DETAILS_ERROR,
  payload: { message },
});
export const getOrgbasedRosterdata = (data) => ({
  type: GET_ORG_BASED_ROSTER_DATA,
  payload: data,
});
export const getOrgbasedRosterdataSuccess = (info) => ({
  type: GET_ORG_BASED_ROSTER_DATA_SUCCESS,
  payload: info,
});
export const getOrgbasedRosterdataError = (message) => ({
  type: GET_ORG_BASED_ROSTER_DATA_ERROR,
  payload: { message },
});
export const toggleRoster = (data) => ({
  type: TOGGLE_ROSTER,
  payload: data,
});
export const toggleRosterSuccess = (info) => ({
  type: TOGGLE_ROSTER_SUCCESS,
  payload: info,
});
export const toggleRosterError = (message) => ({
  type: TOGGLE_ROSTER_ERROR,
  payload: { message },
});
export const addNewRoster = (data) => ({
  type: ADD_NEW_ROSTER,
  payload: data,
});
export const addNewRosterSuccess = (info) => ({
  type: ADD_NEW_ROSTER_SUCCESS,
  payload: info,
});
export const addNewRosterError = (message) => ({
  type: ADD_NEW_ROSTER_ERROR,
  payload: { message },
});
export const addNewOrganization = (data) => ({
  type: ADD_NEW_ORGANIZATION,
  payload: data,
});
export const addNewOrganizationSuccess = (info) => ({
  type: ADD_NEW_ORGANIZATION_SUCCESS,
  payload: info,
});
export const addNewOrganizationError = (message) => ({
  type: ADD_NEW_ORGANIZATION_ERROR,
  payload: { message },
});
export const getTitleRole = (data) => ({
  type: GET_TITLE_ROLE,
  payload: data,
});
export const getTitleRoleSuccess = (info) => ({
  type: GET_TITLE_ROLE_SUCCESS,
  payload: info,
});
export const getTitleRoleError = (message) => ({
  type: GET_TITLE_ROLE_ERROR,
  payload: { message },
});
export const getTitleRoleOption = (data) => ({
  type: GET_TITLE_ROLE_OPTION,
  payload: data,
});
export const getTitleRoleOptionSuccess = (info) => ({
  type: GET_TITLE_ROLE_OPTION_SUCCESS,
  payload: info,
});
export const getTitleRoleOptionError = (message) => ({
  type: GET_TITLE_ROLE_OPTION_ERROR,
  payload: { message },
});
export const mapNewTitleRole = (data) => ({
  type: MAP_NEW_TITLE_ROLE,
  payload: data,
});
export const mapNewTitleRoleSuccess = (info) => ({
  type: MAP_NEW_TITLE_ROLE_SUCCESS,
  payload: info,
});
export const mapNewTitleRoleError = (message) => ({
  type: MAP_NEW_TITLE_ROLE_ERROR,
  payload: { message },
});
export const exportRoster = (data) => ({
  type: EXPORT_ROSTER,
  payload: data,
});
export const exportRosterSuccess = (info) => ({
  type: EXPORT_ROSTER_SUCCESS,
  payload: info,
});
export const exportRosterReset = (payload) => ({
  type: EXPORT_ROSTER_RESET,
  payload: payload,
});
export const exportRosterError = (message) => ({
  type: EXPORT_ROSTER_ERROR,
  payload: { message },
});
export const getManageRoles = (data) => ({
  type: GET_MANAGE_ROLES,
  payload: data,
});
export const getManageRolesSuccess = (info) => ({
  type: GET_MANAGE_ROLES_SUCCESS,
  payload: info,
});

export const getManageRolesError = (message) => ({
  type: GET_MANAGE_ROLES_ERROR,
  payload: { message },
});
export const exportActiveRoster = (data) => ({
  type: EXPORT_ACTIVE_RECORD,
  payload: data,
});
export const exportActiveRosterSuccess = (info) => ({
  type: EXPORT_ACTIVE_RECORD_SUCCESS,
  payload: info,
});
export const exportActiveRosterReset = (payload) => ({
  type: EXPORT_ACTIVE_RECORD_RESET,
  payload: payload,
});
export const exportActiveRosterError = (message) => ({
  type: EXPORT_ACTIVE_RECORD_ERROR,
  payload: { message },
});
